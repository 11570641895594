import Lottie from 'lottie-react';
import animationOne from '../assets/new-assets/animation-one.json';

const LottieAnimationOne = ({ setCurrentDiv, lottieRef }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    speed: 0.5,
    style: {
      width: '100%',
      height: '100%'
    }
  };

  const style = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div style={style}>
      <Lottie
        lottieRef={lottieRef}
        {...defaultOptions}
        animationData={animationOne}
        onComplete={() => {
          setCurrentDiv(2);
        }}
        className='lottie-animation'
      />
    </div>
  );
};

export default LottieAnimationOne;
