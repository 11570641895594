import React, { useRef, useState } from 'react';
import {
  BlueNext,
  BluePrev,
  NextIcon,
  PrevIcon,
  WatchVideo
} from '../constants/asset-constants';
import Slider from 'react-slick';
import { getTestimonials } from './new-home-page/constants';
import { ReactComponent as QuoteIcon } from '../components/new-home-page/assests/quote-icon.svg';

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAfterChange = index => {
    setActiveIndex(index);
  };
  const sliderRef = useRef();

  return (
    <section className='new-home-page__testimonial'>
      <QuoteIcon className='testimonial__quote__icon' />
      <h2 className='header-title'>
        Loved & trusted by
        <br />
        business of all sizes
      </h2>
      <Slider
        dots
        autoplay
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        ref={sliderRef}
        arrows={false}
        afterChange={handleAfterChange}
        appendDots={dots => (
          <div
            style={{
              position: 'absolute',
              bottom: '-60px',
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              background: '#FFFFFF0D',
              borderRadius: '50px',
              padding: '10px 6px 3px 0px'
            }}
          >
            <ul style={{ margin: '0px' }}> {dots} </ul>
          </div>
        )}
        customPaging={i => (
          <div
            style={{
              width: '12px',
              height: '12px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '50%',
              background: i === activeIndex ? '#fff' : '#ffffff33',
              transition: 'background 0.3s'
            }}
          />
        )}
      >
        {getTestimonials.map((test, index) => {
          return (
            <div key={index} className='homepage-container'>
              <div className='testimonial-contents'>
                <div className='testimonial-content-video'>
                  <img
                    alt={'author picture'}
                    className='img-fluid testimonial-image'
                    src={test.authorImg}
                  />
                </div>
                <div className='testimonial-content-details'>
                  <div className='testimonial-details'>
                    <div className='testimonial-merchant-contents'>
                      <p className='details testimonial-description '>
                        {test.description}
                      </p>
                      <div>
                        <div className='testimonial-wrap'>
                          <div>
                            <p className='testimonial-author mb-0'>
                              {test.author}
                            </p>
                            <p className='testimonial-company'>
                              {test.company}
                            </p>
                          </div>
                          <img className='test-logo' src={test.logo} alt='' />
                        </div>
                        {test.videoId ? (
                          <div
                            onClick={() =>
                              window.open(`${test.videoId}`, '_blank').focus()
                            }
                            style={{
                              marginTop: '20px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              gap: '5px',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <img
                              src={WatchVideo}
                              className='img-fluid'
                              alt=''
                            />
                            <span className='playlist-text'>Watch video</span>{' '}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          width: 'auto',
          marginTop: '20px'
        }}
        className='carousel-arrows'
      >
        <img
          src={BluePrev}
          alt=''
          className='carousel-arrow'
          style={{
            cursor: 'pointer'
          }}
          onClick={() => sliderRef.current?.slickPrev()}
        />
        <img
          src={BlueNext}
          alt=''
          className='carousel-arrow'
          style={{
            cursor: 'pointer'
          }}
          onClick={() => sliderRef.current?.slickNext()}
        />
      </div>
    </section>
  );
};

export default Testimonials;
