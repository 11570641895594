import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import LottieAnimationOne from './lottie-animation-one';
import LottieAnimationTwo from './lottie-animation-two';
import LottieAnimationThree from './lottie-animation-three';
import { useInView } from 'react-intersection-observer';

const AnimatedBoxes = () => {
  const [currentDiv, setCurrentDiv] = useState(1);
  const lottieRef = useRef(null);
  const { ref: containerRef, inView } = useInView({
    threshold: 0.1
  });

  useEffect(() => {
    if (inView) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.stop();
    }
  }, [inView]);

  const containerStyle = {
    width: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const divStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem',
    borderRadius: '2.4rem'
  };

  return (
    <>
      <div style={containerStyle} className='animated__container'>
        <AnimatePresence>
          {currentDiv === 1 && (
            <motion.div
              key='div1'
              initial={{ y: '200%' }}
              animate={{ y: 0 }}
              exit={{ x: '-100%', opacity: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              style={{
                ...divStyle
              }}
            >
              <LottieAnimationOne
                setCurrentDiv={setCurrentDiv}
                lottieRef={lottieRef}
              />
            </motion.div>
          )}
          {currentDiv === 2 && (
            <motion.div
              key='div2'
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ y: '100%', opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                ...divStyle
              }}
            >
              <LottieAnimationTwo setCurrentDiv={setCurrentDiv} />
            </motion.div>
          )}
          {currentDiv === 3 && (
            <motion.div
              key='div3'
              initial={{ y: '-100%' }}
              exit={{ y: '-100%', opacity: 0 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                ...divStyle
              }}
            >
              <LottieAnimationThree setCurrentDiv={setCurrentDiv} />
            </motion.div>
          )}
        </AnimatePresence>
        <p ref={containerRef} style={{ position: 'absolute', bottom: 0 }}></p>
      </div>
    </>
  );
};

export default AnimatedBoxes;
