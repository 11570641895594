import React from 'react';
import QRCode from 'qrcode.react';
import { downloadApp2, MobileAppImg } from '../../constants/asset-constants';

const GetMobileApp = () => {
  const downloadAppLink = 'https://onelink.to/jzcdu2';

  return (
    <section className='new-home-page__mobile-app'>
      <div className='mobile-content'>
        <h2 className='header web-scan'>
          Scan to download the Topship Mobile App
        </h2>
        <h1 className='header mobile-app'>The Topship Mobile App Is Here</h1>
        <p>
          The Topship mobile app is now available on Google{' '}
          <br className='show-break' /> Play Store and App Store. Download the
          Mobile App now.
        </p>
        <div
          onClick={() => window.open(downloadAppLink, '_blank')}
          className='new-home-page__mobile-app__download'
        >
          <img
            src={downloadApp2}
            alt='Download Topship App'
            className='img-fluid'
          />
        </div>
      </div>

      <div className='barcode-container'>
        <QRCode
          value={downloadAppLink}
          bgColor='#22428F'
          fgColor='#ffffff'
          size={180}
        />
      </div>
      <div className='image-container'>
        <img src={MobileAppImg} alt='developer-img' />
      </div>
    </section>
  );
};
export default GetMobileApp;
