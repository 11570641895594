import Lottie from 'lottie-react';
import animation from '../assets/new-assets/animation-three.json';

const LottieAnimationThree = ({ setCurrentDiv }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    style: {
      width: '100%',
      height: '100%'
    }
  };

  const style = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div style={style}>
      <Lottie
        {...defaultOptions}
        animationData={animation}
        onComplete={() => {
          setCurrentDiv(1);
        }}
      />
    </div>
  );
};

export default LottieAnimationThree;
